import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { trackBannerClick } from "../../utils/datalayerPush";

const Banner = ({ bannerList }) => {
  return (
    <Container
      className="banner_list maxWidthContainerFluid paddingContainerFluid"
      fluid
    >
      <Row>
        {bannerList !== undefined &&
          bannerList.map((each, i) => 
            {
              const urlParts = each.url.split("/").filter(Boolean);
              const lastPart = urlParts[urlParts.length - 1] || "Banner Item";
              const formattedTitle = lastPart.replace(/[-_]/g, " ");
              return(
            <Col xs={6} lg={3} className="banner_column mt-2" key={i}>
              <Card>
                <NavLink to={each.url} className="text-decoration-none"
                  onClick={() => trackBannerClick(formattedTitle)}
                >
                  <Card.Img
                    variant="top"
                    src={each.banner_image}
                    //className="banner"
                  />
                </NavLink>
              </Card>
            </Col>
          )}
          )}
      </Row>
    </Container>
  );
};

export default Banner;
